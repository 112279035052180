import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatDate } from "date-fns";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useEthPaymentExecute } from "../hooks/useMutation";
import { IPayment } from "../models/IPayment";
import { SLATE_GREY } from "../theme/colors";
import { cryptoIcons } from "../utils/iconLists";
import { cryptoNetworks, formatDateStr } from "../utils/utils";

const boxStyles = {
  minWidth: { xs: "100%", sm: 300 },
  height: 1,
  borderRadius: 2,
  border: `1px solid ${SLATE_GREY}`,
  wordBreak: "break-word",
};

export const PaymentRecord = (props: { payment: IPayment }) => {
  const { payment } = props;
  const { mutateAsync: executePayment, isLoading } = useEthPaymentExecute();
  const { formatMessage } = useIntl();

  const isCrypto = useMemo(
    () =>
      cryptoNetworks.some((network) => network.chainName === payment.method),
    [payment.method]
  );

  const network = useMemo(
    () =>
      cryptoNetworks.find((network) => network.chainName === payment.method),
    [payment.method]
  );

  const methodName =
    payment.method.charAt(0).toUpperCase() + payment.method.slice(1);

  const method =
    isCrypto && network ? (
      <Stack direction="row" gap={1}>
        {cryptoIcons[network?.nativeCurrency.symbol.toLowerCase()]}
        <Typography variant="body2">{methodName}</Typography>
      </Stack>
    ) : (
      <Typography variant="body2">{methodName}</Typography>
    );

  const isPaymentSuccess = payment.status === "completed";

  const amount = !isCrypto || !network
    ? `$${payment.amount}`
    : `${payment.amount} ${network.nativeCurrency.symbol}`;

  const handlePaymentRerun = async () => {
    if (!isCrypto || isPaymentSuccess) return;

    const {
      paymentId: transactionHash,
      userId,
      amount,
      tokens,
      method,
    } = payment;
    
    await executePayment({
      txHash: transactionHash,
      userId: userId,
      tokens: tokens,
      amount: amount,
      network: method,
    });
  };

  const status = (
    <Tooltip
      arrow
      title={
        isPaymentSuccess
          ? formatMessage({ id: "Payment.success" })
          : formatMessage({ id: "Payment.problems" })
      }
    >
      <IconButton onClick={handlePaymentRerun}>
        {isLoading ? (
          <CircularProgress size="24px" color="warning" />
        ) : isPaymentSuccess ? (
          <CheckCircleOutlineOutlinedIcon color="success" />
        ) : isCrypto ? (
          <ErrorOutlineOutlinedIcon color="warning" />
        ) : (
          <ErrorOutlineOutlinedIcon color="warning" />
        )}
      </IconButton>
    </Tooltip>
  );

  return (
    <Box sx={boxStyles}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "flex-start", sm: "space-between" }}
        alignItems={{ xs: "flex-start", sm: "center" }}
        padding={2}
      >
        <Stack>
          {method}
          {isCrypto && network ? (
            <Link
              href={`${network.blockExplorerUrls[0]}/tx/${payment.paymentId}`}
            >
              <Typography variant="body2">
                <FormattedMessage id="Payment.crypto.checkTx" />
              </Typography>
            </Link>
          ) : null}
        </Stack>
        <Stack>
          <Typography variant="body1">
            <FormattedMessage id="Payment.amount" values={{ amount: amount }} />
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="body1">
            <FormattedMessage
              id="Payment.date"
              values={{ date: formatDate(payment.createdAt, formatDateStr) }}
            />
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="body1">{status}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
