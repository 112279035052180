export const WHITE = "#ffffff";
export const BLACK = "#000000";
export const DARK_BLACK = "#1a1a1a";
export const MIDNIGHT_BLUE = "#2c3e50";
export const DARK_GREY = "#4f5b62";
export const DARKER_GREY = "#62727b";
export const LIGHT_GREY = "#7c8a97";
export const SLATE_GREY = "#717b8c";
export const NOBLE_GOLD = "#e6be8a";
export const YELLOW = "yellow";
export const BRIGHT_GREEN = "#00FF00";
export const LIME_GREEN = "#32CD32";
export const RED = "#f44336";
export const CHARCOAL = "#1c262b";
export const WHITE_ALPHA_LIGHT = "rgba(255, 255, 255, 0.3)";
export const WHITE_ALPHA_MEDIUM = "rgba(255, 255, 255, 0.5)";
export const LIGHT_WHITE = "rgba(255, 255, 255, 0.7)";
export const GREY_ALPHA_DARK = "rgba(128, 128, 128, 0.9)";
export const LIGHT_BLUE_GREY = "#b0bec5";
export const BLACK_ALPHA = "rgba(255, 255, 255, 0.3)";
export const BLACK_ALPHA_DARKER = "rgba(255, 255, 255, 0.5)";


