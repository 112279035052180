import { Grid, Stack, Typography } from "@mui/material";
import { ITarotCardResponse } from "../models/IPrediction";
import { getCardImagePath } from "../utils/utils";
import { LoadingComponent } from "./LoadingComponent";
import { TarotCard } from "./TarotCard";
import { FormattedMessage } from "react-intl";
import { HelpIcon } from "./UserProfile";

interface IPredictionCardsViewProps {
  cards: ITarotCardResponse[];
}

export const PredictionDecisionCardsView = (
  props: IPredictionCardsViewProps
) => {
  const { cards } = props;

  if (!cards || cards.length < 7) {
    return <LoadingComponent isLoading />;
  }

  const significator: ITarotCardResponse = cards[6];
  const doingOutcome: ITarotCardResponse[] = [cards[2], cards[0], cards[4]];
  const notDoingOutcome: ITarotCardResponse[] = [cards[3], cards[1], cards[5]];

  return (
    <Grid container spacing={2} justifyContent="center" mt={5}>
      <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
        <Stack spacing={4} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <HelpIcon text="QuestionForm.significator.help" />
            <Typography variant="h5">
              <FormattedMessage id="QuestionForm.significator" />
            </Typography>
          </Stack>
          <TarotCard
            key={significator.number}
            number={significator?.number}
            name={significator?.clearName}
            height={300}
            reversed={significator?.reversed}
            image={getCardImagePath(significator?.clearName)}
          />
        </Stack>
      </Grid>
      <Grid item mt={2} width={1} justifyContent="center">
        <Typography variant="h5" textAlign="center" my={4}>
          <FormattedMessage id="QuestionForm.ifYouDo" />
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {doingOutcome.map((x) => (
            <Grid
              item
              key={x.number}
              display="flex"
              justifyContent="center"
            >
              <TarotCard
                number={x.number}
                name={x.clearName}
                reversed={x.reversed}
                image={getCardImagePath(x.clearName)}
                height={300}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item mt={2} width={1} justifyContent="center">
        <Typography variant="h5" textAlign="center" my={4}>
          <FormattedMessage id="QuestionForm.ifYouDont" />
        </Typography>
        <Grid container spacing={2} width={1} justifyContent="center">
          {notDoingOutcome.map((x) => (
            <Grid
              item
              key={x.number}
              display="flex"
              justifyContent="center"
            >
              <TarotCard
                number={x.number}
                name={x.clearName}
                reversed={x.reversed}
                image={getCardImagePath(x.clearName)}
                height={300}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
