import { Box, Fade, Grid, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ICryptoToken } from "../models/ICryptoToken";
import { ITokenPack } from "../models/ITokenPack";
import { cryptoTokens } from "../utils/utils";
import TokenPack from "./TokenPack";
import { CryptoEthPayment } from "./payments/CryptoEthPayment";

const tokenPacks: ITokenPack[] = [
  {
    image: "/images/basic_token_pack.png",
    description: "TokenPack.baseTokenPack.description",
    cost: 0.0001,
    tokens: 100,
    title: "TokenPack.baseTokenPack",
  },
  {
    image: "/images/pro_token_pack.png",
    description: "TokenPack.proTokenPack.description",
    cost: 15,
    tokens: 200,
    title: "TokenPack.proTokenPack",
  },
  {
    image: "/images/vip_token_pack.png",
    description: "TokenPack.vipTokenPack.description",
    cost: 20,
    tokens: 350,
    title: "TokenPack.vipTokenPack",
  },
];

export const Shop = () => {
  const [tokenPack, setTokenPack] = useState<ITokenPack | null>(null);
  const [cryptoToken, setCryptoToken] = useState<ICryptoToken | null>();
  const topRef = useRef<HTMLDivElement>(null);
  const [key, setKey] = useState(0);

  const handleCryptoTokenChange = (token: ICryptoToken) => {
    setCryptoToken(token);
  };

  const handleCryptoTokenClose = () => {
    setCryptoToken(null);
    setTokenPack(null);
  };

  return (
    <Box
      key={key}
      ref={topRef}
      width={1}
      display="flex"
      justifyContent="center"
      margin="auto"
      height={1}
      minHeight={400}
      pb={15}
    >
      <Stack gap={3} justifyContent="center">
        <Typography variant="h1">
          <FormattedMessage id="Shop.title" />
        </Typography>
        <Fade in={tokenPack !== null} timeout={1000}>
          <Grid container spacing={3} justifyContent="center" height={1}>
            <Grid item height={1}>
              {tokenPack ? (
                <Grid item container height={1} spacing={2}>
                  {cryptoTokens
                    .filter((token) => {
                      if (!cryptoToken) return true;
                      return token === cryptoToken;
                    })
                    .map((token) => (
                      <Grid item key={token.symbol}>
                        <CryptoEthPayment
                          onClick={handleCryptoTokenChange}
                          amount={tokenPack.cost}
                          tokens={tokenPack.tokens}
                          cryptoToken={token}
                          onClose={handleCryptoTokenClose}
                        />
                      </Grid>
                    ))}
                </Grid>
              ) : (
                <Box sx={{ height: 120 }} />
              )}
            </Grid>
          </Grid>
        </Fade>
        <Grid container spacing={3} justifyContent="center" height={1}>
          {tokenPacks.map((pack) => (
            <Grid item key={pack.title}>
              <TokenPack
                isSelected={tokenPack === pack}
                tokenPack={pack}
                onSelect={(selectedPack) => {
                  setTokenPack(selectedPack);
                  setKey((prevKey) => prevKey + 1);
                  topRef.current?.scrollIntoView({ behavior: "smooth" });
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};
