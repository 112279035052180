import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useUserPayments } from "../hooks/useRequest";
import { userIdStorageName } from "../utils/utils";
import { PaymentRecord } from "./PaymentRecord";
import { ContactUsButton } from "./ContactUsButton";

export const UserProfilePayments = () => {
  const userId = sessionStorage.getItem(userIdStorageName);
  const { data: payments = [], isLoading } = useUserPayments(userId || "");

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack width={1} height={1} gap={2}>
      <Typography variant="h5">
        <FormattedMessage id="UserProfile.payments" />
      </Typography>
      {payments.length === 0 ? (
        <Box
          display="flex"
          height={1}
          minHeight={550}
          justifyContent="center"
          alignItems="center"
        >
          <FormattedMessage id="UserProfile.payments.noPayments" />
        </Box>
      ) : null}
      <Stack gap={1} minHeight={550} maxHeight={550} width={1} overflow="auto" p={2}>
        {payments
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
          .map((payment) => (
            <PaymentRecord key={payment.id} payment={payment} />
          ))}
      </Stack>
      <Stack alignItems="center">
        <ContactUsButton showText />
      </Stack>
    </Stack>
  );
};
