import { Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

// Array of phrases to display
const phrases = [
  "Loading.phrases.one",
  "Loading.phrases.two",
  "Loading.phrases.three",
  "Loading.phrases.four",
];

export const LoadingComponent = (props: { isLoading: boolean }) => {
  const { isLoading } = props;
  const [currentPhrase, setCurrentPhrase] = useState("Loading.phrases.focus");

  // Change the phrase every 2.5 seconds
  useEffect(() => {
    if (isLoading) {
      const intervalId = setInterval(() => {
        const randomPhrase =
          phrases[Math.floor(Math.random() * phrases.length)];
        setCurrentPhrase(randomPhrase);
      }, 2500);

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [isLoading]);

  return isLoading ? (
    <Stack justifyContent="center" textAlign="center">
      <CircularProgress sx={{ alignSelf: "center" }} />
      <Typography mt={5}>
        <FormattedMessage id={currentPhrase} />
      </Typography>
      <Typography mt={5}>
        <FormattedMessage id="Loading.phrases.doNotRefresh" />
      </Typography>
    </Stack>
  ) : null;
};
