import { Box, IconButton, IconButtonProps, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { NOBLE_GOLD } from "../theme/colors";
import { Link as RouteLink } from "react-router-dom";
import { GrContact } from "react-icons/gr";

export const ContactUsButton = (
  props: { showText?: boolean } & IconButtonProps
) => {
  const { formatMessage } = useIntl();
  const { showText, sx } = props;
  return (
    <Box>
      <IconButton
        title={formatMessage({ id: "MailForm.title" })}
        size="small"
        component={RouteLink}
        to="/contact-us"
        color="inherit"
        sx={{
          ...sx,
          gap: 1,
          "&:hover": {
            color: NOBLE_GOLD,
          },
        }}
      >
        {showText ? (
          <Typography variant="caption" color="white">
            <FormattedMessage id="MailForm.title" />
          </Typography>
        ) : null}
        <GrContact />
      </IconButton>
    </Box>
  );
};
