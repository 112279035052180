import TextField from "@mui/material/TextField";
import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useIntl } from "react-intl";
import { IPredictionRequest } from "../models/IPredictionRequest";

export const QuestionField = (props: {
  methods: UseFormReturn<IPredictionRequest, any, undefined>;
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const { formatMessage } = useIntl();

  const messageValue = props.methods.watch("message");
  const charCount = (messageValue || "").length;

  return (
    <TextField
      label={
        isFocused
          ? `${formatMessage({
              id: "QuestionFrom.question",
            })} (${charCount} / 150)`
          : formatMessage({ id: "QuestionFrom.question" })
      }
      variant="outlined"
      {...props.methods.register("message", {
        required: formatMessage({ id: "QuestionFrom.questionIsRequired" }),
        maxLength: {
          value: 150,
          message: formatMessage({ id: "QuestionFrom.questionCannotExceed" }),
        },
      })}
      error={!!props.methods.formState.errors.message}
      helperText={props.methods.formState.errors.message?.message as string}
      margin="normal"
      placeholder={formatMessage({ id: "QuestionFrom.askAQuestion" })}
      multiline
      fullWidth
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      rows={3}
    />
  );
};
