import ReplayIcon from "@mui/icons-material/Replay";
import { Box, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { arabicToRoman } from "../utils/utils";

export interface ITarotCard {
  number: number;
  name: string;
  image: string;
  reversed: boolean;
  height?: number;
}

interface TooltipBoxProps {
  children: React.ReactNode;
  title: string;
  top: string;
  left: string;
  iconSize: number;
}

const HEIGHT = 400;
const AR = 0.77;

export const TarotCard = (props: ITarotCard) => {
  const { name, number, image, reversed, height = HEIGHT } = props;
  const [imgSrc, setImgSrc] = useState(image);

  const cardName = name.charAt(0).toUpperCase() + name.slice(1);

  const handleImageError = () => {
    setImgSrc("/images/noimage.png");
  };

  const iconSize = height / 6;

  return (
    <Card sx={{ width: "100%", maxWidth: height * AR, position: "relative" }}>
      <CardMedia
        sx={{
          minHeight: height,
          transform: reversed ? "rotate(180deg)" : "none",
        }}
        component="img"
        onError={handleImageError}
        image={imgSrc}
        title={cardName}
        role="tarot-card"
      />
      {height >= 200 ? (
        <CardContent>
          {reversed ? (
            <TooltipBox
              title={"Reversed"}
              top="7.7%"
              left="12%"
              iconSize={iconSize}
            >
              <ReplayIcon
                sx={{ color: "error.main", fontSize: iconSize / 2 }}
              />
            </TooltipBox>
          ) : null}
          <TooltipBox
            title={`${cardName}, tarot number: ${number}`}
            top="7.7%"
            left="88%"
            iconSize={iconSize}
          >
            <Typography
              color="white"
              fontWeight={700}
              sx={{ fontSize: iconSize / 4 }}
            >
              {arabicToRoman(number)}
            </Typography>
          </TooltipBox>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            textAlign="center"
            sx={{ fontSize: height / 20 }}
          >
            {cardName}
          </Typography>
        </CardContent>
      ) : null}
    </Card>
  );
};

const TooltipBox = ({
  children,
  title,
  top,
  left,
  iconSize,
}: TooltipBoxProps) => (
  <Tooltip title={title} arrow>
    <Box
      sx={{
        position: "absolute",
        top: top,
        left: left,
        cursor: "help",
        transform: "translate(-50%, -50%)",
        width: iconSize,
        height: iconSize,
        borderRadius: "50%",
        border: (theme) => `2px solid ${theme.palette.warning.main}`,
        opacity: 0.75,
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
      }}
    >
      {children}
    </Box>
  </Tooltip>
);
