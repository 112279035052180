import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { formatDate } from "date-fns";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { IPredictionHistoryItem } from "../models/IPredictHistoryItem";
import { componentPerSpread, formatDateStr, renderTarotCards } from "../utils/utils";
import { ErrorBox } from "./ErrorBox";
import { TarotCard } from "./TarotCard";

interface IPredictionHistoryItemProps {
  item: IPredictionHistoryItem;
}

export const PredictionHistoryItem = (props: IPredictionHistoryItemProps) => {
  const { item } = props;
  const SpreadComponent = componentPerSpread.find(
    (x) => x.spreadType === item.spreadType
  )?.component;

  if (!SpreadComponent) {
    return <ErrorBox error="Error.historyNotLoaded" />;
  }

  return (
    <Stack gap={4} mt={2} mb={2}>
      <Box>
        <Typography variant="h5" color="warning.main" align="center">
          {item.history.question}
        </Typography>
      </Box>
      <SpreadComponent cards={item.history.result.cards} />
      <Box>
        <Typography overflow="auto" sx={{ whiteSpace: "pre-line" }}>
          {item.history.result.content}
        </Typography>
      </Box>
    </Stack>
  );
};

export const PredictionHistoryItemShort = (
  props: IPredictionHistoryItemProps
) => {
  const { item } = props;
  const navigate = useNavigate();
  return (
    <Button
      sx={{ padding: 4, minWidth: "100%" }}
      variant="contained"
      onClick={() => navigate(`/prediction-history/${item.id}`)}
    >
      <Grid container spacing={3} alignContent="center">
        <Grid item justifyContent="left">
          <Stack gap={2} width={1}>
            <Typography color="text.secondary" align="left">
              Question: "{item.history.question}"
            </Typography>
            <Typography color="warning.main" align="left">
              <FormattedMessage id={`QuestionForm.spread.${item.spreadType}`} />
            </Typography>
            <Typography color="secondary.main" align="left">
              {formatDate(item.date, formatDateStr)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item ml="auto" mb="auto">
          <Grid container spacing={1} justifyContent="center" mt={2}>
            {renderTarotCards(item.history.result.cards).map((card) => (
              <Grid item key={card.name}>
                <TarotCard
                  name={card.name}
                  number={card.number}
                  image={card.image}
                  reversed={card.reversed}
                  height={50}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Button>
  );
};
