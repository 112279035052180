import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { IUserProfile } from "../models/IUser";
import { useMemo, useState } from "react";
import {
  deleteCookie,
  tokenStorageName,
  userIdStorageName,
} from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

interface ISetting {
  name: string;
  onClick?: () => void;
}

const useSettings = () => {
  const navigate = useNavigate();

  return [
    { name: "UserProfile.title", onClick: () => navigate("/user-profile") },
    {
      name: "UserProfile.menu.history",
      onClick: () => navigate("/prediction-history"),
    },
    {
      name: "UserProfile.menu.logout",
      onClick: () => {
        sessionStorage.removeItem(tokenStorageName);
        sessionStorage.removeItem(userIdStorageName);
        deleteCookie("g_state", process.env.PUBLIC_URL, "/");
        window.location.href = "/login";
      },
    },
  ];
};

export const UserProfileMenu = (props: {
  user?: IUserProfile;
  isLoading?: boolean;
}) => {
  const { user, isLoading } = props;
  const settings = useSettings();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const balance: string = useMemo(
    () =>
      user
        ? user.balance > 999
          ? "999+"
          : user.balance < 0
          ? "less than 0"
          : `${user.balance}`
        : "0",
    [user]
  );

  const userName: string = useMemo(
    () =>
      user
        ? user.username.length > 15
          ? `${user.username.slice(0, 12)}...`
          : user.username
        : "",
    [user]
  );

  const handleSettingClick = (setting: ISetting) => {
    setting.onClick && setting.onClick();
    setAnchorElUser(null);
  };

  if (!user || isLoading)
    return <CircularProgress size="24px" color="warning" />;

  return (
    <Box>
      <Stack direction="row" gap={3} justifyContent="left">
        <IconButton role="user-profile" sx={{ p: 0 }} onClick={handleOpenUserMenu}>
          <Avatar alt={user?.username} src="1" />
        </IconButton>
        <Stack>
          <Typography color="warning.main" variant="body1">
            {userName}
          </Typography>
          <Stack direction="row" gap={1}>
            <Typography variant="subtitle2" component="span">
              <FormattedMessage id="UserProfile.balance" />
            </Typography>
            <Typography
              variant="subtitle2"
              component="span"
              sx={{
                color: user
                  ? user.balance <= 0
                    ? "error.main"
                    : "success.main"
                  : "error.main",
              }}
            >
              {balance}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Menu
        sx={{ mt: "60px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem
            key={setting.name}
            onClick={() => handleSettingClick(setting)}
          >
            <Button fullWidth color="inherit" variant="text">
              <FormattedMessage id={setting.name} />
            </Button>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
