import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { usePrediction } from "../hooks/useMutation";
import { IPrediction, ITarotCardResponse } from "../models/IPrediction";
import { IPredictionRequest } from "../models/IPredictionRequest";
import { TTarotSpread } from "../models/TTarotSpread";
import { componentPerSpread, userIdStorageName } from "../utils/utils";
import { ErrorBox } from "./ErrorBox";
import { LoadingComponent } from "./LoadingComponent";
import { QuestionField } from "./QuestionField";
import { useMediaQuery, Theme } from "@mui/material";

export const QuestionForm = () => {
  const { mutateAsync: askQuestion, isLoading } = usePrediction();

  const userId = sessionStorage.getItem(userIdStorageName);
  const { formatMessage } = useIntl();

  const [spreadType, setSpreadType] = useState<TTarotSpread>("standard");
  const [cards, setCards] = useState<ITarotCardResponse[]>([]);
  const [prediction, setPrediction] = useState<IPrediction | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const isScreenSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const methods = useForm<IPredictionRequest>();

  const onSubmit = async (data: IPredictionRequest) => {
    const preparedData = {
      ...data,
      message: data.message.trim(),
      spreadType: spreadType,
    };

    try {
      const result = await askQuestion(preparedData);
      if (result) {
        setIsSuccess(true);
        setPrediction(result);
        setCards(result.cards);
      }
    } catch (error) {
      setError((error as Error).message);
      setIsSuccess(false);
    }
  };

  const question = methods.watch("message");

  const parsedPrediction = prediction ? prediction.content : "";
  const regex = /\{number:(Card_)?\d+\} \{name:(.*?)\}/g;
  const replacedPrediction = parsedPrediction.replace(
    regex,
    (_, __, cardName) => `${cardName.trim()}`
  );

  const SpreadComponent = componentPerSpread.find(
    (x) => x.spreadType === spreadType
  )?.component;

  if (!SpreadComponent) {
    return <ErrorBox error={"No such spread"} />;
  }

  if (!userId) {
    throw new Error(formatMessage({ id: "Errors.notAuthorized" }));
  }

  if (isLoading) {
    return <LoadingComponent isLoading={isLoading} />;
  }

  return (
    <Box width={1} height={1}>
      {error ? <ErrorBox error={error?.toString()} /> : null}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        mt={1}
        display="flex"
      >
        {Boolean(prediction) && isSuccess ? (
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item sm={8} md={6}>
              <Typography variant="h5" color="warning.main" align="center">
                {question}
              </Typography>
            </Grid>
            <SpreadComponent cards={cards} />
            <Grid item sm={8} md={6} justifyContent="center" display="flex">
              <Typography overflow="auto" sx={{ whiteSpace: "pre-line" }}>
                {replacedPrediction}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Box width={1} mt={2}>
        <Box width={isScreenSmall ? "100%" : "50%"} height={1} mt={3}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              select
              label={<FormattedMessage id="QuestionForm.chooseSpread" />}
              value={spreadType}
              onChange={(e) => {
                setPrediction(undefined);
                setSpreadType(e.target.value as TTarotSpread);
              }}
            >
              <MenuItem value={"standard" as TTarotSpread}>
                <Stack direction="row" gap={1} alignItems="center">
                  <LiveHelpOutlinedIcon fontSize="small" />
                  <FormattedMessage id="QuestionForm.spread.standard" />
                </Stack>
              </MenuItem>
              <MenuItem value={"decision" as TTarotSpread}>
                <Stack direction="row" gap={1} alignItems="center">
                  <TipsAndUpdatesOutlinedIcon fontSize="small" />
                  <FormattedMessage id="QuestionForm.spread.decision" />
                </Stack>
              </MenuItem>
            </TextField>
          </FormControl>
        </Box>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
          autoComplete="off"
          style={{ width: "100%" }}
        >
          <QuestionField methods={methods} />
          <Box width={1} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              <FormattedMessage
                id={prediction ? "QuestionFrom.askAnother" : "QuestionFrom.ask"}
              />
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
