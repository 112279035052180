import ErrorIcon from "@mui/icons-material/ErrorOutline";
import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const ErrorBox = (props: { error: string }) => {
  return (
    <Stack width={1} gap={1} direction="row" justifyContent="center">
      <ErrorIcon color="error" />
      <Typography variant="body1" align="left" color="error.main" flexWrap="wrap" whiteSpace="break-spaces">
        <FormattedMessage id={props.error.replace("Error: ", "")} />
      </Typography>
    </Stack>
  );
};
