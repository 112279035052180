import { Box, Icon } from "@mui/material";
import React from "react";

interface IconProps {
  alt: string;
  imagePath: string;
}

const PngIcon: React.FC<IconProps> = ({ alt, imagePath, ...otherProps }) => {
  return (
    <Icon {...otherProps}>
      <Box
        component="img"
        src={imagePath}
        alt={alt}
        sx={{ width: 24, height: 24 }}
      />
    </Icon>
  );
};

export default PngIcon;