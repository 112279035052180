import { PredictionDecisionCardsView } from "../components/PredictionDecisionCardsView";
import { PredictionStandardCardsView } from "../components/PredictionStandardCardsView";
import { ITarotCard } from "../components/TarotCard";
import { ICryptoNetwork } from "../models/ICryptoNetwork";
import { ICryptoToken } from "../models/ICryptoToken";
import { ISpreadViewComponent } from "../models/ISpreadViewComponent";

export const getCardImagePath = (cardName: string) => {
  return `/images/${cardName.replaceAll(" ", "_")}.png` ?? "";
};

export function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length >= 2) {
    return parts[1].split(";")[0];
  }
  return undefined;
}

export function deleteCookie(
  name: string,
  domain: string = "",
  path: string = "/"
) {
  if (getCookie(name)) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;${
      domain ? ` domain=${domain};` : ""
    }${path ? ` path=${path};` : ""}`;
  }
}

export const tokenStorageName =
  process.env.REACT_APP_TOKEN_STORAGE_NAME ?? "tarot_token";

export const userIdStorageName =
  process.env.REACT_APP_USER_ID_STORAGE_NAME ?? "tarot_id";

export const etheriumWallet = process.env.REACT_APP_ETHERIUM_WALLET ?? "";

export const renderTarotCards = (
  cards: {
    clearName: string;
    number: number;
    reversed: boolean;
  }[]
): ITarotCard[] => {
  return cards.map((card) => {
    return {
      name: card.clearName,
      number: card.number,
      reversed: card.reversed,
      image: getCardImagePath(card.clearName.toLowerCase()),
    };
  });
};

export const arabicToRoman = (num: number): string => {
  const romanNumerals = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };

  let roman = "";

  for (let key in romanNumerals) {
    while (num >= romanNumerals[key as keyof typeof romanNumerals]) {
      roman += key;
      num -= romanNumerals[key as keyof typeof romanNumerals];
    }
  }

  return roman;
};

export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const componentPerSpread: ISpreadViewComponent[] = [
  { spreadType: "standard", component: PredictionStandardCardsView },
  { spreadType: "decision", component: PredictionDecisionCardsView },
];

export const localeList = [
  { locale: "en", flag: "us" },
  { locale: "de", flag: "de" },
  { locale: "fr", flag: "fr" },
  { locale: "it", flag: "it" },
  { locale: "es", flag: "es" },
  { locale: "ru", flag: "ru" },
  { locale: "ua", flag: "ua" },
  { locale: "ge", flag: "ge" },
];

export const cryptoTokens: ICryptoToken[] = [
  {
    name: "Matic",
    symbol: "MATIC",
    contractAddress: "",
    decimals: 18,
    network: "polygon",
    tokenAbi: [],
  },
  {
    name: "Ethereum",
    symbol: "ETH",
    contractAddress: "",
    decimals: 18,
    network: "ethereum",
    tokenAbi: [],
  },
];

export const cryptoNetworks: ICryptoNetwork[] = [
  {
    chainName: "polygon",
    chainId: "0x89",
    nativeCurrency: cryptoTokens.find(
      (token) => token.symbol === "MATIC"
    ) as ICryptoToken,
    rpcUrls: ["https://polygon-mainnet.infura.io/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  {
    chainName: "ethereum",
    chainId: "0x1",
    nativeCurrency: cryptoTokens.find(
      (token) => token.symbol === "ETH"
    ) as ICryptoToken,
    rpcUrls: ["https://mainnet.infura.io/v3/"],
    blockExplorerUrls: ["https://etherscan.io/"],
  },
];

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  export const formatDateStr = "dd.MM.yyyy HH:mm";