import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useAddUser } from "../hooks/useMutation";
import { IUser } from "../models/IUser";
import { EMAIL_PATTERN } from "../utils/utils";
import { ContactUsButton } from "./ContactUsButton";
import { ErrorBox } from "./ErrorBox";
import { LocaleSwitcher } from "./LocaleSwitcher";

const RegistrationForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<
    IUser & {
      confirmPassword: string;
      isEighteenOrOlder: boolean;
      acceptTerms: boolean;
    }
  >();

  const { formatMessage } = useIntl();

  const { mutate: addUser, isLoading, isError, error } = useAddUser();

  const onSubmit = (data: IUser) => {
    const updatedData = {
      ...data,
      email: data.email.toLowerCase().trim(),
      password: data.password.trim(),
      username: data.username.trim(),
    };
    addUser(updatedData, {
      onSuccess: () => {
        window.location.href = "/";
      },
      onError: (error) => {
        console.error("Failed to login:", error);
      },
    });
  };

  const [isTermsDialogOpen, setTermsDialogOpen] = useState(false);

  const handleTermsDialogOpen = () => setTermsDialogOpen(true);
  const handleTermsDialogClose = () => setTermsDialogOpen(false);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack gap={2} maxWidth={400}>
      {isError && error ? <ErrorBox error={error.toString()} /> : null}
      <LocaleSwitcher />
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <Grid
          container
          spacing={2}
          direction="column"
          display="flex"
          justifyContent="flex-start"
        >
          <Grid item>
            <TextField
              label={<FormattedMessage id="RegisterForm.name" />}
              variant="outlined"
              autoComplete="new-password"
              {...register("username", {
                required: formatMessage({ id: "RegisterForm.nameIsRequired" }),
                maxLength: {
                  value: 40,
                  message: formatMessage({
                    id: "RegisterForm.nameCannotExceed",
                  }),
                },
              })}
              error={!!errors.username}
              helperText={errors.username?.message as string}
              fullWidth
              inputProps={{
                autoComplete: "off",
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label={<FormattedMessage id="emailLabel" />}
              variant="outlined"
              {...register("email", {
                required: formatMessage({ id: "emailIsRequired" }),
                pattern: {
                  value: EMAIL_PATTERN,
                  message: formatMessage({ id: "invalidEmailAddress" }),
                },
              })}
              error={!!errors.email}
              helperText={errors.email?.message as string}
              fullWidth
              inputProps={{
                autoComplete: "new-email",
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label={<FormattedMessage id="passwordLabel" />}
              type="password"
              variant="outlined"
              {...register("password", {
                required: formatMessage({ id: "passwordIsRequired" }),
              })}
              error={!!errors.password}
              helperText={errors.password?.message as string}
              fullWidth
              inputProps={{
                autoComplete: "new-password",
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label={<FormattedMessage id="RegisterForm.confirmPassword" />}
              type="password"
              variant="outlined"
              {...register("confirmPassword", {
                required: formatMessage({
                  id: "RegisterForm.confirmPasswordIsRequired",
                }),
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { password } = getValues();
                    return (
                      password === value ||
                      formatMessage({ id: "RegisterForm.passwordsShouldMatch" })
                    );
                  },
                },
              })}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message as string}
              fullWidth
              inputProps={{
                autoComplete: "new-password",
              }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              sx={{ width: 1 }}
              control={
                <Checkbox
                  {...register("isEighteenOrOlder", {
                    required: formatMessage({
                      id: "RegisterForm.youMustBeEighteenOrOlder",
                    }),
                  })}
                />
              }
              label={<FormattedMessage id="RegisterForm.iAmEighteenOrOlder" />}
            />
            {errors.isEighteenOrOlder && (
              <Typography
                mt={-1}
                ml="14px"
                color="error.main"
                sx={{ fontSize: "0.75rem", textAlign: "left" }}
              >
                {errors.isEighteenOrOlder.message}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <FormControlLabel
              sx={{ width: 1 }}
              control={
                <Checkbox
                  {...register("acceptTerms", {
                    required: formatMessage({
                      id: "RegisterForm.youMustAcceptTheTermsAndConditions",
                    }),
                  })}
                />
              }
              label={
                <Typography variant="body1">
                  <span>
                    <FormattedMessage id="RegisterForm.iHaveReadAndAcceptedTheTermsAndAgreement" />
                    <Link onClick={handleTermsDialogOpen}>
                      <FormattedMessage id="RegisterForm.termsAndConditions" />
                    </Link>
                  </span>
                </Typography>
              }
            />
            {errors.acceptTerms && (
              <Typography
                ml="14px"
                mt={-1}
                color="error.main"
                sx={{ fontSize: "0.75rem", textAlign: "left" }}
              >
                {errors.acceptTerms.message}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              <FormattedMessage id="RegisterForm.register" />
            </Button>
          </Grid>
        </Grid>
        <Dialog open={isTermsDialogOpen} onClose={handleTermsDialogClose}>
          <DialogTitle>
            <FormattedMessage id="RegisterForm.termsAndConditions" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Stack gap={2}>
                <Typography sx={{ whiteSpace: "pre-line" }}>
                  <FormattedMessage id="TermsAndConditions.text" />
                </Typography>
                <ContactUsButton showText />
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleTermsDialogClose}>
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </Stack>
  );
};

export default RegistrationForm;
