import React, { useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import messages_de from "../locales/de.json";
import messages_en from "../locales/en.json";
import messages_es from "../locales/es.json";
import messages_it from "../locales/it.json";
import messages_ru from "../locales/ru.json";
import messages_ua from "../locales/ua.json";
import messages_fr from "../locales/fr.json";
import messages_ge from "../locales/ge.json";

type Messages = {
  [locale: string]: {
    [key: string]: string;
  };
};

export const LocaleContext = React.createContext({
  locale: "en",
  setLocale: (locale: string) => {},
});

const messages: Messages = {
  en: messages_en,
  de: messages_de,
  ru: messages_ru,
  ua: messages_ua,
  it: messages_it,
  es: messages_es,
  fr: messages_fr,
  ge: messages_ge,
};

export const LocaleProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [locale, setLocale] = useState(() => {
    const savedLocale = localStorage.getItem("locale");
    return savedLocale ?? navigator.language.split("-")[0] ?? "en";
  });

  useEffect(() => {
    localStorage.setItem("locale", locale);
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export const useLocale = () => useContext(LocaleContext);
