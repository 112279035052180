import { useParams } from "react-router-dom";
import { userIdStorageName } from "../utils/utils";
import { usePredictionHistory } from "../hooks/useRequest";
import { ErrorBox } from "./ErrorBox";
import { PredictionHistoryItem } from "./PredictionHistoryItem";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";

export const PredictionHistoryDetail = () => {
  const { id } = useParams();
  const userId = sessionStorage.getItem(userIdStorageName);
  const { formatMessage } = useIntl();

  if (!userId) {
    throw new Error(formatMessage({ id: "Errors.notAuthorized" }));
  }

  const { data: predictionHistory } = usePredictionHistory(userId);

  const item = predictionHistory?.find((item) => item.id === id);

  if (!item) {
    return <ErrorBox error="Error.historyNotLoaded" />;
  }

  return (
    <Box>
      <PredictionHistoryItem item={item} />
    </Box>
  );
};
