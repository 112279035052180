import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ITokenPack } from "../models/ITokenPack";
import { LIGHT_GREY, WHITE } from "../theme/colors";
import { HelpIcon } from "./UserProfile";

interface TokenPackProps {
  tokenPack: ITokenPack;
  isSelected: boolean;
  onSelect: (tokenPack: ITokenPack) => void;
}

const TokenPack = (props: TokenPackProps) => {
  const { tokenPack, isSelected, onSelect } = props;
  const { image, description, cost, tokens, title } = tokenPack;

  const handleSelect = () => {
    onSelect(tokenPack);
    window.scrollTo(0, 0);
  };

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: "400px",
        display: {
          "@media (orientation: portrait)": "flex",
          "@media (orientation: landscape)": "flex",
        },
        flexDirection: {
          "@media (orientation: portrait)": "column",
          "@media (orientation: landscape)": "column",
        },
        border: isSelected ? "2px solid" : "none", // adjust border thickness as needed
        borderColor: isSelected ? "error.main" : "transparent",
        ":hover": {
          border: "1px solid",
          borderColor: "secondary.main",
        },
      }}
    >
      <CardMedia
        component="img"
        image={image}
        alt="Token Pack"
        sx={{
          maxWidth: 400,
          height: "auto",
        }}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Stack gap={2}>
          <Typography variant="h6">
            <FormattedMessage id={title} />
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: LIGHT_GREY,
              wordBreak: "normal",
              whiteSpace: "pre-wrap",
              hyphens: "auto",
            }}
          >
            <FormattedMessage id={description} />
          </Typography>
          <Typography variant="body2" color={WHITE}>
            ${cost}
          </Typography>
          <Stack direction="row" gap={1}>
            <Typography variant="body2">
              <FormattedMessage
                id="TokenPack.tokens"
                values={{ amount: tokens }}
              />
            </Typography>
            <HelpIcon text="TokenPack.help" />
          </Stack>
          <Button
            disabled={isSelected}
            variant="contained"
            onClick={handleSelect}
            startIcon={<PaidOutlinedIcon />}
          >
            <FormattedMessage id="TokenPack.buy" values={{ amount: cost }} />
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TokenPack;
