import { Box, Paper, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { ContactUsButton } from "./components/ContactUsButton";
import TopBar from "./components/TopBar";
import { AppRoutes } from "./components/routes/AppRoutes";
import { LocaleProvider } from "./context/LocaleContext";
import { tokenStorageName } from "./utils/utils";
import { Link as RouteLink } from "react-router-dom";
import { MetaMaskUIProvider } from "@metamask/sdk-react-ui";

const metamaskOptions = {
  dappMetadata: {
    name: "Oraculis",
    url: window.location.href,
  },
  infuraAPIKey: process.env.REACT_APP_INFURA_API_KEY,
}

function App() {
  const token = sessionStorage.getItem(tokenStorageName);

  return (
    <MetaMaskUIProvider
      sdkOptions={metamaskOptions}
    >
    <LocaleProvider>
      <Router>
        <div className="App">
          {token ? <TopBar /> : null}
          <Box
            overflow="auto"
            m="auto"
            width={0.8}
            p={2}
            display="flex"
            justifyContent="center"
            pt="5em"
            pb="10em"
          >
            <AppRoutes />
          </Box>
        </div>
        <Paper
          sx={{
            bgcolor: "black",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
          elevation={3}
        >
          <Box
            sx={{
              bgcolor: "black",
              display: "flex",
              padding: "1em",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              width={1}
              alignItems="center"
            >
              <Typography variant="caption" color="white">
                <FormattedMessage id="Copyright.main" />
              </Typography>
              <Typography color="text.secondary" sx={{ ml: "1em" }} variant="caption" component={RouteLink} to="/terms-and-conditions">
                <FormattedMessage id="RegisterForm.termsAndConditions" />
              </Typography>
              <Typography color="text.secondary" sx={{ ml: "1em" }} variant="caption" component={RouteLink} to="/privacy">
                <FormattedMessage id="PrivacyPolicy.title" />
              </Typography>
              {token ? <ContactUsButton sx={{ ml: "1em" }} /> : null}
            </Box>
          </Box>
        </Paper>
      </Router>
    </LocaleProvider>
    </MetaMaskUIProvider>
  );
}

export default App;
