import { parseEther } from "@ethersproject/units";
import { useAccount, useConnect, useSDK } from "@metamask/sdk-react-ui";
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Link,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "react-query";
import { useEthPaymentCreate, useEthPaymentExecute } from "../../hooks/useMutation";
import { useEthTokenToUsdtRate } from "../../hooks/useRequest";
import { ICryptoNetwork } from "../../models/ICryptoNetwork";
import { ICryptoToken } from "../../models/ICryptoToken";
import { GREY_ALPHA_DARK, MIDNIGHT_BLUE } from "../../theme/colors";
import { cryptoIcons } from "../../utils/iconLists";
import {
  cryptoNetworks,
  etheriumWallet,
  userIdStorageName,
} from "../../utils/utils";
import { ContactUsButton } from "../ContactUsButton";
import { ErrorBox } from "../ErrorBox";

const ERROR_MESSAGES = {
  NO_METAMASK: "Payment.eth.noMetamask",
  NO_NETWORK: "Payment.eth.noNetwork",
  NOT_FUNDS: "Payment.eth.notFunds",
  FAILED: "Payment.failed",
  USER_DENIED: "Payment.eth.userDenied",
  ANOTHER_REQUEST_PENDING: "Payment.eth.anotherRequestPending",
  GAS_PRICE_TOO_LOW: "Payment.eth.gasPriceTooLow",
  NONCE_TOO_LOW: "Payment.eth.nonceTooLow",
  RPC_ERROR: "Payment.failed",
  CONTRACT_EXECUTION_ERROR: "Payment.failed",
};

function getErrorMessage(error: unknown): string {
  if (typeof error === "string") {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (
    typeof error === "object" &&
    error !== null &&
    "data" in error &&
    "message" in (error.data as { message: string })
  ) {
    return (error.data as { message: string }).message as string;
  }

  if (typeof error === "object" && error !== null && "message" in error) {
    return error.message as string;
  }

  return String(error);
}

function handleError(
  error: unknown,
  setError: React.Dispatch<React.SetStateAction<string | null>>
) {
  const errorMessage = getErrorMessage(error).toLowerCase();

  if (errorMessage.includes("insufficient funds")) {
    setError(ERROR_MESSAGES.NOT_FUNDS);
    return;
  }

  if (errorMessage.includes("wallet_switchethereumchain")) {
    setError(ERROR_MESSAGES.ANOTHER_REQUEST_PENDING);
    return;
  }

  if (
    errorMessage.includes("user rejected") ||
    errorMessage.includes("user denied")
  ) {
    setError(ERROR_MESSAGES.USER_DENIED);
    return;
  }

  if (errorMessage.includes("gas price too low for acceptance")) {
    setError(ERROR_MESSAGES.GAS_PRICE_TOO_LOW);
    return;
  }

  if (errorMessage.includes("nonce too low")) {
    setError(ERROR_MESSAGES.NONCE_TOO_LOW);
    return;
  }

  if (errorMessage.includes("execution reverted")) {
    setError(ERROR_MESSAGES.CONTRACT_EXECUTION_ERROR);
    return;
  }

  if (errorMessage.includes("internal json-rpc error")) {
    setError(ERROR_MESSAGES.RPC_ERROR);
    return;
  }

  setError(errorMessage);
}

interface IPaymentProps {
  amount: number;
  tokens: number;
  cryptoToken: ICryptoToken;
  onClick: (cryptoToken: ICryptoToken) => void;
  onClose: () => void;
}

export const CryptoEthPayment = (props: IPaymentProps) => {
  const { amount, tokens, cryptoToken, onClick, onClose } = props;

  const { data: priceUsd } = useEthTokenToUsdtRate(cryptoToken.symbol);
  const { mutateAsync: executePayment } = useEthPaymentExecute();
  const { mutateAsync: createPayment } = useEthPaymentCreate();

  const amountOfCrypto = amount / priceUsd;

  const userId = sessionStorage.getItem(userIdStorageName);

  const [transactionHash, setTransactionHash] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [payResult, setPayResult] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const network = cryptoNetworks.find(
    (n) => n.chainName.toLowerCase() === cryptoToken.network.toLowerCase()
  );
  
  const { provider, chainId  } = useSDK();
  const { connect } = useConnect();
 
  const createPaymentAsync = async () => {
    if (transactionHash && userId) {
      try {
        const newPayment = await createPayment({
          txHash: transactionHash,
          userId: userId,
          tokens: tokens,
          amount: amount,
          network: cryptoToken.network,
        });
        return newPayment;
      } catch (error) {
        handleError(error, setError);
      }
    }
  };

  const executePaymentAsync = async () => {
    if (transactionHash && userId) {
      try {
        const paymentResult = await executePayment({
          txHash: transactionHash,
          userId: userId,
          tokens: tokens,
          amount: amount,
          network: cryptoToken.network,
        });
        if (paymentResult.message === "success") {
          setPayResult("Payment.success");
        } else {
          throw new Error(ERROR_MESSAGES.FAILED);
        }
      } catch (error) {
        handleError(error, setError);
      }
    }
  };

  useEffect(() => {
    if (transactionHash && payResult) return;
    // Save payment in status "preparing"
    createPaymentAsync();
    executePaymentAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionHash]);

  const createTransaction = useMutation(async () => {
    try {
      if (transactionHash || error || payResult) return;

      if (!window.ethereum || !provider) {
        const dappUrl = window.location.href.split("//")[1].split("/")[0];
        window.open(`https://metamask.app.link/dapp/${dappUrl}`, "_blank");
        throw new Error(ERROR_MESSAGES.NO_METAMASK);
      }

      connect();

      const accounts =
        ((await provider.request({
          method: "eth_requestAccounts",
        })) as string[]) ?? [];

      if (!network) {
        throw new Error(ERROR_MESSAGES.NO_NETWORK);
      }

      if (chainId !== network.chainId) {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: network.chainId }],
        });
      }

      const roundedAmountOfCrypto = amountOfCrypto.toFixed(18);

      const tx = await provider.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: accounts[0],
            to: etheriumWallet,
            value: parseEther(roundedAmountOfCrypto).toHexString(),
          },
        ],
      });

      return tx;

    } catch (error) {
      console.log(error);
      handleError(error, setError);
    }
  });

  const handleSubmit = useCallback(async () => {
    if (isDialogOpen) {
      return;
    }

    setIsDialogOpen(true);
    onClick(cryptoToken);
    try {
      const txHash = await createTransaction.mutateAsync();
      if (txHash) {
        setTransactionHash(txHash as string);
      }
    } finally {
      setIsDialogOpen(false);
    }
  }, [isDialogOpen, onClick, cryptoToken, createTransaction]);

  if (!network) {
    return <ErrorBox error={ERROR_MESSAGES.NO_NETWORK} />;
  }

  return (
    <Box
      gap={1}
      width={1}
      display="flex"
      border={`1px solid ${GREY_ALPHA_DARK}`}
      bgcolor={MIDNIGHT_BLUE}
      justifyContent="center"
      alignItems="center"
      borderRadius={2}
      p={3}
      onClick={handleSubmit}
      sx={{
        ":hover": {
          border: "1px solid",
          borderColor: "secondary.main",
          cursor: "pointer",
          color: "warning.main",
        },
        wordBreak: "break-all",
      }}
    >
      {error ? (
        <Stack gap={2}>
          <ErrorBox error={error} />
          {transactionHash ? (
            <Stack gap={1}>
              <Typography variant="body2">
                <FormattedMessage id="Payment.crypto.checkTx" />:
              </Typography>
              <Typography variant="body2" color="warning.main">
                <Link
                  target="_blank"
                  href={`${network.blockExplorerUrls[0]}/tx/${transactionHash}`}
                >
                  {transactionHash}
                </Link>
              </Typography>
            </Stack>
          ) : null}
          <ContactUsButton showText />
        </Stack>
      ) : transactionHash && payResult ? (
        <Typography color="success.main" sx={{ wordBreak: "break-word" }}>
          <FormattedMessage id={payResult} />
        </Typography>
      ) : transactionHash && !payResult ? (
        <PaymentInProgress network={network} txHash={transactionHash} />
      ) : amountOfCrypto && !isNaN(amountOfCrypto) ? (
        <Stack
          direction="row"
          gap={1}
          justifyContent="center"
          alignItems="center"
        >
          {cryptoIcons[cryptoToken.symbol.toLowerCase()]}

          <Typography variant="body2">
            <FormattedMessage
              id="Payment.payCrypto"
              values={{
                tokenName: cryptoToken.symbol,
                amount: amount,
                cryptoAmount:
                  amountOfCrypto > 1
                    ? amountOfCrypto.toFixed(2)
                    : amountOfCrypto.toFixed(4),
              }}
            />
          </Typography>
        </Stack>
      ) : (
        <CircularProgress size="24px" color="primary" />
      )}
      {error || payResult ? (
        <IconButton
          color="secondary"
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </Box>
  );
};

const PaymentInProgress = (props: {
  network: ICryptoNetwork;
  txHash: string;
}) => {
  const { network } = props;
  const link = `${network.blockExplorerUrls[0]}tx/${props.txHash}`;
  return (
    <Modal
      open={true}
      components={{
        Backdrop: Backdrop,
      }}
      componentsProps={{
        backdrop: {
          sx: { color: "#fff", backgroundColor: "rgba(0, 0, 0, 0.5)" },
        },
      }}
    >
      <Box
        sx={{
          bgcolor: "black",
          borderRadius: 2,
          border: "1px solid white",
          maxWidth: 400,
          maxHeight: 400,
          position: "fixed",
          height: "100%",
          justifyContent: "center",
          display: "flex",
          wordBreak: "break-all",
          p: 4,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: "auto",
        }}
      >
        <Stack gap={2} alignItems="center" justifyContent="center">
          <CircularProgress size="24px" sx={{ mt: 3}} />
          <Typography>
            <FormattedMessage id="Payment.paymentInprocess" />
          </Typography>
          <Typography>
            <FormattedMessage id="Loading.phrases.doNotRefresh" />
          </Typography>
          <Typography color="text.primary" variant="caption">
            <FormattedMessage id="Payment.crypto.checkTx" />:
          </Typography>
          <Typography
            variant="caption"
            component={Link}
            href={link}
            target="_blank"
            color="warning.main"
          >
            {props.txHash}
          </Typography>
        </Stack>
      </Box>
    </Modal>
  );
};
