import { Box, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { LocaleSwitcher } from "./LocaleSwitcher";
import { ContactUsButton } from "./ContactUsButton";

export const PrivacyPolicy = () => {
  return (
    <Stack width={1} height={1}>
      <Typography variant="h1">
        <FormattedMessage id="PrivacyPolicy.title" />
      </Typography>
      <Box m={2}>
      <LocaleSwitcher />
        <Box mt={2} />
        <Typography variant="body1" whiteSpace="break-spaces">
          <FormattedMessage id="PrivacyPolicy.text" />
        </Typography>
        <ContactUsButton showText />
      </Box>
    </Stack>
  );
};
