import GoogleIcon from "@mui/icons-material/Google";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { detectIncognito } from "detectincognitojs";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useGoogleLogin } from "../hooks/useMutation";
import { tokenStorageName, userIdStorageName } from "../utils/utils";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleSignInButton: React.FC = () => {
  const {
    mutate: authenticateWithGoogle,
    isLoading,
    isError,
  } = useGoogleLogin();

  const { formatMessage } = useIntl();

  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [isIncognito, setIsIncognito] = useState<boolean>(false);

  useEffect(() => {
    detectIncognito().then((result) => {
      setIsIncognito(result.isPrivate);
    });
  }, []);

  useEffect(() => {
    const checkGoogleLibrary = () => {
      if (window.google) {
        setGoogleLoaded(true);
        window.google.accounts.id.initialize({
          client_id: clientId,
          use_fedcm_for_prompt: false,
          callback: (response: any) => {
            authenticateWithGoogle(response.credential, {
              onSuccess: (data) => {
                sessionStorage.setItem(tokenStorageName, data.token);
                sessionStorage.setItem(userIdStorageName, data.id);
                window.location.href = "/";
              },
              onError: (error) => {
                console.error("Google login failed:", error);
              },
            });
          },
        });
      } else {
        setTimeout(checkGoogleLibrary, 500);
      }
    };

    checkGoogleLibrary();
  }, [authenticateWithGoogle]);

  const handleGoogleSignIn = () => {
    window.google.accounts.id.prompt();
  };

  const buttonText = useMemo(
    () =>
      formatMessage({
        id: isIncognito
          ? "GoogleButton.notAvailableInIncognitoMode"
          : "GoogleButton.signInWithGoogle",
      }),
    [isIncognito, formatMessage]
  );

  return (
    <Stack>
      <Button
        onClick={handleGoogleSignIn}
        variant="contained"
        disabled={!googleLoaded || isIncognito || isLoading}
        sx={{
          "&:hover": {
            ".MuiSvgIcon-root": { color: "#FFFFFF" },
          },
        }}
        startIcon={
          googleLoaded && !isLoading ? (
            <GoogleIcon fontSize="small" />
          ) : (
            <CircularProgress size={18} color="secondary" />
          )
        }
      >
        {buttonText}
      </Button>
      {isError ? (
        <Typography variant="subtitle2" color="error" textAlign="center">
          <FormattedMessage id="GoogleButton.failedToLoginWithGoogle" />
        </Typography>
      ) : null}
    </Stack>
  );
};

export default GoogleSignInButton;
