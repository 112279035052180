import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  usePromoCode,
  useUpdateUser,
  useUpdateUserPassword,
} from "../hooks/useMutation";
import { useUser } from "../hooks/useRequest";
import { IUser, IUserProfile } from "../models/IUser";
import {
  DARK_GREY,
  GREY_ALPHA_DARK,
  MIDNIGHT_BLUE,
  NOBLE_GOLD,
} from "../theme/colors";
import { userIdStorageName } from "../utils/utils";
import { ContactUsButton } from "./ContactUsButton";
import { ErrorBox } from "./ErrorBox";

export const HelpIcon = (props: { text: string }) => (
  <Tooltip arrow title={<FormattedMessage id={props.text} />}>
    <HelpOutlineIcon
      color="secondary"
      sx={{
        fontSize: 18,
        cursor: "pointer",
        "&:hover": {
          color: (theme) => theme.palette.text.primary,
        },
      }}
    />
  </Tooltip>
);

export const UserProfile = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const userId = sessionStorage.getItem(userIdStorageName);

  const { data: user, isLoading } = useUser(userId ?? "");
  const { mutate: updateUser } = useUpdateUser();
  const mutation = useUpdateUserPassword();
  const {
    mutate: applyPromoCode,
    isError: isPromoCodeError,
    error: promoCodeError,
  } = usePromoCode();

  const [isEditUsername, setEditUsername] = useState(false);
  const [isChangePassword, setChangePassword] = useState(false);
  const [username, setUsername] = useState(user?.username);
  const [promoCode, setPromoCode] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    trigger,
    reset,
  } = useForm<IUserProfile>({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit: handleSubmitPassword,
    register: registerPassword,
    formState: { errors: errorsPassword, isDirty: isDirtyPassword },
    trigger: triggerPassword,
    reset: resetPassword,
    getValues,
  } = useForm<IUser & { confirmPassword: string }>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    if (!user) return;
    setUsername(user.username);
    reset(user);
  }, [user, reset]);

  const onSubmit = async (data: IUserProfile) => {
    if (!userId) return;
    triggerPassword();
    await updateUser({ userId, updatedData: data });
    setEditUsername(false);
    reset(user);
  };

  const onSubmitPassword = handleSubmitPassword(async (data) => {
    if (!userId) return;
    await mutation.mutate({ userId, newPassword: data.password });
    setChangePassword(false);
    resetPassword();
  });

  const {
    onChange: registerOnChange,
    onBlur: registerOnBlur,
    ...registerProps
  } = register("username", {
    required: formatMessage({ id: "RegisterForm.nameIsRequired" }),
    maxLength: {
      value: 40,
      message: formatMessage({
        id: "RegisterForm.nameCannotExceed",
      }),
    },
  });

  if (isLoading)
    return <Skeleton variant="rectangular" width="100%" height={118} />;

  if (!user) return <ErrorBox error="Error.getUserFailed" />;

  return (
    <Box
      sx={{
        border: `1px solid ${MIDNIGHT_BLUE}`,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
        bgcolor: "background.default",
        p: 2,
        borderRadius: 2,
        width: "100%",
        maxWidth: "75vw",
        overflow: "hidden",
        mx: "auto",
      }}
    >
      <Stack spacing={3} alignItems="center">
        <Box width={1} display="flex" justifyContent="flex-start">
          <Typography variant="h5" color="warning.main" gutterBottom>
            <FormattedMessage id="UserProfile.title" />
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <Stack gap={3}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography>
                    <FormattedMessage id="emailLabel" />
                  </Typography>
                  <Tooltip
                    title={
                      <FormattedMessage id="UserProfile.cantChangeEmail" />
                    }
                  >
                    <Link sx={{ wordBreak: "break-word" }}>
                      <Typography color={GREY_ALPHA_DARK} noWrap={false}>
                        {user.email}
                      </Typography>
                    </Link>
                  </Tooltip>
                </Stack>
                {!isEditUsername ? (
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography>
                      <FormattedMessage id="RegisterForm.name" />
                    </Typography>
                    <Link
                      role="edit-username"
                      onClick={() => setEditUsername(true)}
                      sx={{ wordBreak: "break-word" }}
                    >
                      <Typography color={NOBLE_GOLD} noWrap={false}>
                        {username}
                      </Typography>
                    </Link>
                    <HelpIcon text="UserProfile.editUserNameHelp" />
                  </Stack>
                ) : (
                  <TextField
                    autoFocus
                    label={<FormattedMessage id="RegisterForm.name" />}
                    variant="outlined"
                    error={!!errors.username}
                    helperText={errors.username?.message as string}
                    fullWidth
                    onChange={(e) => {
                      setUsername(e.target.value);
                      registerOnChange(e);
                    }}
                    onBlur={async (e) => {
                      if (await trigger("username")) {
                        setEditUsername(false);
                        registerOnBlur(e);
                      }
                    }}
                    {...registerProps}
                    inputProps={{
                      autoComplete: "off",
                    }}
                    autoComplete="new-password"
                  />
                )}
                {isDirty ? (
                  <Stack direction="row" spacing={2} justifyContent="flex-end">
                    {!errors.username ? (
                      <Button variant="contained" color="primary" type="submit">
                        <FormattedMessage id="save" />
                      </Button>
                    ) : null}
                    <Button
                      type="reset"
                      variant="outlined"
                      onClick={() => {
                        reset(user);
                        setUsername(user.username);
                        setEditUsername(false);
                      }}
                    >
                      <FormattedMessage id="cancel" />
                    </Button>
                  </Stack>
                ) : null}
                {!isChangePassword ? (
                  <Link
                    role="change-password"
                    sx={{ wordBreak: "break-word" }}
                    onClick={() => setChangePassword(true)}
                  >
                    <FormattedMessage id="UserProfile.changePassword" />
                  </Link>
                ) : (
                  <Stack
                    spacing={2}
                    p={2}
                    border={`1px solid ${DARK_GREY}`}
                    borderRadius={2}
                    width={1}
                  >
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton
                        color="warning"
                        onClick={() => {
                          setChangePassword(false);
                          resetPassword();
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Stack gap={2}>
                      <TextField
                        label={<FormattedMessage id="newPasswordLabel" />}
                        type="password"
                        variant="outlined"
                        error={!!errorsPassword.password}
                        helperText={errorsPassword.password?.message as string}
                        fullWidth
                        inputProps={{
                          autoComplete: "new-password",
                        }}
                        {...registerPassword("password", {
                          required: formatMessage({
                            id: "passwordIsRequired",
                          }),
                        })}
                      />
                      <TextField
                        label={
                          <FormattedMessage id="RegisterForm.confirmPassword" />
                        }
                        type="password"
                        variant="outlined"
                        {...registerPassword("confirmPassword", {
                          required: formatMessage({
                            id: "RegisterForm.confirmPasswordIsRequired",
                          }),
                          validate: {
                            matchesPreviousPassword: (value) => {
                              const { password } = getValues();
                              return (
                                password === value ||
                                formatMessage({
                                  id: "RegisterForm.passwordsShouldMatch",
                                })
                              );
                            },
                          },
                        })}
                        error={!!errorsPassword.confirmPassword}
                        helperText={
                          errorsPassword.confirmPassword?.message as string
                        }
                        fullWidth
                        inputProps={{
                          autoComplete: "new-password",
                        }}
                      />
                      <Box>
                        {isDirtyPassword ? (
                          <Button
                            variant="contained"
                            onClick={onSubmitPassword}
                          >
                            <FormattedMessage id="UserProfile.changePassword" />
                          </Button>
                        ) : null}
                      </Box>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </form>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              spacing={2}
              p={2}
              border={`1px solid ${DARK_GREY}`}
              borderRadius={2}
            >
              <Typography variant="body1">
                <FormattedMessage id="UserProfile.balance" /> {user.balance}
              </Typography>
              <Stack direction="row" gap={1}>
                <Button
                  variant="contained"
                  sx={{ maxWidth: 200 }}
                  onClick={() => navigate("/shop")}
                >
                  <FormattedMessage id="UserProfile.addTokens" />
                </Button>
                <Button
                  variant="contained"
                  sx={{ maxWidth: 200 }}
                  onClick={() => navigate("/payments")}
                >
                  <FormattedMessage id="UserProfile.paymentsHistory" />
                </Button>
              </Stack>
              <TextField
                label={<FormattedMessage id="UserProfile.promoCode" />}
                placeholder={formatMessage({
                  id: "UserProfile.enterPromoCode",
                })}
                value={promoCode}
                error={isPromoCodeError}
                helperText={
                  isPromoCodeError ? (
                    <FormattedMessage id={(promoCodeError as Error)?.message} />
                  ) : undefined
                }
                onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
                fullWidth
                InputProps={{
                  endAdornment: promoCode ? (
                    <Box display="flex" justifyContent="flex-end" width={1}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          applyPromoCode({
                            promo: promoCode,
                            userId: userId ?? "",
                          });
                          setPromoCode("");
                        }}
                      >
                        <FormattedMessage id="UserProfile.applyPromoCode" />
                      </Button>
                    </Box>
                  ) : (
                    <HelpIcon text="UserProfile.promoCodeHelp" />
                  ),
                }}
              />
              <ContactUsButton showText />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};
