import { Navigate, Route, Routes } from "react-router-dom";
import { tokenStorageName } from "../../utils/utils";
import LoginForm from "../LoginForm";
import { PredictionHistoryDetail } from "../PredictionHistoryDetail";
import { PredictionHistoryForm } from "../PredictionHistoryForm";
import { PrivacyPolicy } from "../PrivacyPolicy";
import { QuestionForm } from "../QuestionForm";
import RegistrationForm from "../RegistrationForm";
import { Shop } from "../Shop";
import { TermsAndConditions } from "../TermsAndConditions";
import { UserProfile } from "../UserProfile";
import ContactForm from "../ContactForm";
import { UserProfilePayments } from "../UserProfilePayments";

export const AppRoutes = () => {
  const token = sessionStorage.getItem(tokenStorageName);
  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={token ? <Navigate to="/" /> : <LoginForm />}
        />
        <Route path="/register" element={<RegistrationForm />} />
        <Route
          path="/user-profile"
          element={token ? <UserProfile /> : <Navigate to="/login" />}
        />
        <Route path="/contact-us" element={<ContactForm />} />
        <Route
          path="/shop"
          element={token ? <Shop /> : <Navigate to="/login" />}
        />
        <Route
          path="/payments"
          element={token ? <UserProfilePayments /> : <Navigate to="/login" />}
        />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route
          path="/"
          element={token ? <QuestionForm /> : <Navigate to="/login" />}
        />
        <Route
          path="/prediction-history"
          element={token ? <PredictionHistoryForm /> : <Navigate to="/login" />}
        />
        <Route
          path="/prediction-history/:id"
          element={
            token ? <PredictionHistoryDetail /> : <Navigate to="/login" />
          }
        />
      </Routes>
    </>
  );
};
