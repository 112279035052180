import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  Stack,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSendUserRequest } from "../hooks/useMutation";
import { IUserRequest } from "../models/IUserRequest";
import { EMAIL_PATTERN, userIdStorageName } from "../utils/utils";
import { ErrorBox } from "./ErrorBox";
import { FormattedMessage, useIntl } from "react-intl";
import { useUser } from "../hooks/useRequest";

const ContactForm: React.FC = () => {
  const userId = sessionStorage.getItem(userIdStorageName);

  const { data: user } = useUser(userId ?? "");
  const { formatMessage } = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm<IUserRequest>({
    defaultValues: {
      userEmail: user?.email,
      userRequest: "",
    },
  });

  useEffect(() => {
    if (user) {
      setValue("userEmail", user.email);
    }
  }, [user, setValue]);

  const { mutateAsync, isLoading } = useSendUserRequest();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = async (data: IUserRequest) => {
    try {
      const userRequest = { ...data, userId: userId ?? "" };
      await mutateAsync(userRequest);
      setSuccess(true);
    } catch (error) {
      setError(true);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack width={1} height={1} gap={2}>
      <Typography variant="h5" mb={5}>
        <FormattedMessage id="MailForm.title" />
      </Typography>
      {error ? <ErrorBox error="MailForm.error" /> : null}
      {success ? (
        <Stack gap={2} alignItems="center">
          <Typography variant="body2" color="success.main">
            <FormattedMessage id="MailForm.success" />
          </Typography>
          <Box>
            <Button variant="contained" LinkComponent={Link} href="/">
              <FormattedMessage id="Button.back" />
            </Button>
          </Box>
        </Stack>
      ) : null}
      {!success && !error ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          {user ? (
            <Typography>
              <FormattedMessage
                id="MailForm.from"
                values={{ email: user?.email }}
              />
            </Typography>
          ) : (
            <TextField
              label={<FormattedMessage id="emailLabel" />}
              variant="outlined"
              {...register("userEmail", {
                required: formatMessage({ id: "emailIsRequired" }),
                pattern: {
                  value: EMAIL_PATTERN,
                  message: formatMessage({ id: "invalidEmailAddress" }),
                },
              })}
              error={!!errors.userEmail}
              helperText={errors.userEmail?.message as string}
              fullWidth
              inputProps={{
                autoComplete: "new-email",
              }}
            />
          )}
          <TextField
            label={<FormattedMessage id="MailForm.request.title" />}
            variant="outlined"
            {...register("userRequest", { required: true })}
            multiline
            rows={4}
            fullWidth
            margin="normal"
            error={!!errors.userRequest}
            helperText={
              errors.userRequest && (
                <FormattedMessage id="MailForm.request.required" />
              )
            }
          />
          {isDirty && !errors.userRequest && !errors.userEmail ? (
            <Button type="submit" variant="contained" color="primary">
              <FormattedMessage id="MailForm.send" />
            </Button>
          ) : null}
        </form>
      ) : null}
    </Stack>
  );
};

export default ContactForm;
