import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import { usePredictionHistory } from "../hooks/useRequest";
import { userIdStorageName } from "../utils/utils";
import { ErrorBox } from "./ErrorBox";
import { PredictionHistoryItemShort } from "./PredictionHistoryItem";
import { FormattedMessage, useIntl } from "react-intl";

export const PredictionHistoryForm: React.FC = () => {
  const userId = sessionStorage.getItem(userIdStorageName);
  const { formatMessage } = useIntl();
  if (!userId) {
    throw new Error(formatMessage({ id: "Errors.notAuthorized" }));
  }

  const {
    data: predictionHistory = [],
    isLoading,
    isError,
  } = usePredictionHistory(userId);

  const history = predictionHistory.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 100,
  });

  if (!predictionHistory || isLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack justifyContent="center" width={1} p={2}>
      {isError ? <ErrorBox error="Error.historyNotLoaded" /> : null}
      <Typography variant="h5" color="warning.main" gutterBottom>
        <FormattedMessage id="QuestionHistoryForm.title" />
      </Typography>
      <Box
        pr={2}
        pl={1}
        py={2}
        borderTop="1px solid"
        borderColor="background.paper"
        height={700}
        width={1}
      >
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => (
            <List
              width={width}
              height={height}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              rowRenderer={({ key, index, style, parent }): React.ReactNode => {
                const item = history[index];
                return (
                  <CellMeasurer
                    key={key}
                    cache={cache}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                  >
                    <Box style={style} p={1}>
                      <PredictionHistoryItemShort item={item} />
                    </Box>
                  </CellMeasurer>
                );
              }}
              rowCount={history.length}
              overscanRowCount={5}
            />
          )}
        </AutoSizer>
      </Box>
    </Stack>
  );
};
