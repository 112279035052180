import { IconButton } from "@mui/material";
import PngIcon from "./icons/PngIcon";

export interface ISiteMenuIconProps {
  display: { xs: string; md: string };
  onClick?: () => void;
}

export const SiteMenuIcon = (props: ISiteMenuIconProps) => {
  const { display, onClick } = props;
  return (
    <IconButton onClick={onClick} sx={{ display }}>
      <PngIcon imagePath={`logo600.png`} alt={"Tarot predictor"} />
    </IconButton>
  );
};
