import { Box, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { LocaleSwitcher } from "./LocaleSwitcher";
import { ContactUsButton } from "./ContactUsButton";

export const TermsAndConditions = () => {
  return (
    <Stack width={1} height={1}>
      <Typography variant="h1">
        <FormattedMessage id="RegisterForm.termsAndConditions" />
      </Typography>
      <Stack m={2} gap={2} alignItems="flex-start">
      <LocaleSwitcher />
        <Box mt={2} />
        <Typography variant="body1" whiteSpace="break-spaces">
          <FormattedMessage id="TermsAndConditions.text" />
        </Typography>
        <ContactUsButton showText />
      </Stack>
    </Stack>
  );
};
