import { Box, Input, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useContext } from "react";
import ReactCountryFlag from "react-country-flag";
import { LocaleContext } from "../context/LocaleContext";
import { localeList } from "../utils/utils";

export const LocaleSwitcher: React.FC = () => {
  const { locale, setLocale } = useContext(LocaleContext);

  const handleLocaleChange = (event: SelectChangeEvent<string>) => {
    setLocale(event.target.value as string);
  };

  return (
    <Select
      variant="standard"
      value={locale}
      onChange={handleLocaleChange}
      input={<Input disableUnderline />}
      renderValue={(value) => {
        const selectedLocale = localeList.find((x) => x.locale === value);
        return selectedLocale ? (
          <LocaleFlag
            flag={selectedLocale.flag}
            locale={selectedLocale.locale ?? "en"}
          />
        ) : null;
      }}
      sx={{
        "& .MuiSelect-root": {
          backgroundColor: "transparent",
          borderRadius: 0,
          boxShadow: "none",
          padding: 0,
        },
        "& .MuiSelect-select": {
          paddingRight: 0,
          minHeight: "initial",
          backgroundColor: "transparent",
        },
        "& .MuiSelect-icon": {
          display: "none",
        },
        "& .MuiInput-underline:before": {
          borderBottom: "none",
        },
        "& .MuiInput-underline:after": {
          borderBottom: "none",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
          borderBottom: "none",
        },
        "&.Mui-focused .MuiSelect-select": {
          backgroundColor: "transparent",
        },
      }}
    >
      {localeList.map((x) => (
        <MenuItem key={x.locale} value={x.locale}>
          <LocaleFlag flag={x.flag} locale={x.locale ?? "en"} />
        </MenuItem>
      ))}
    </Select>
  );
};

export const LocaleFlag = (props: { locale: string; flag: string }) => {
  const { locale, flag } = props;
  return (
    <Box fontSize={20}>
      <ReactCountryFlag countryCode={flag ?? locale} svg />
    </Box>
  );
};
