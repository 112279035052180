import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useRequest";
import { userIdStorageName } from "../utils/utils";
import { LocaleSwitcher } from "./LocaleSwitcher";
import { SiteMenuIcon } from "./SiteMenuIcon";
import { UserProfileMenu } from "./UserProfileMenu";

const pages = [
  {
    name: "TopBar.menu.ask",
    action: "/",
  },
  // { name: "TopBar.menu.cards", action: "/" },
  { name: "Shop.title", action: "/shop" },
];

function TopBar() {
  const userId = sessionStorage.getItem(userIdStorageName);
  const { data: user, isLoading } = useUser(userId ?? "");
  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  return (
    <AppBar position="fixed" sx={{ bgcolor: "black" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <SiteMenuIcon
            onClick={handleMobileMenuOpen}
            display={{ xs: "flex", md: "none" }}
          />
          <SiteMenuIcon display={{ xs: "none", md: "flex" }} />
          <Dialog
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            fullScreen
          >
            <DialogActions>
              <IconButton color="warning" onClick={handleMobileMenuClose}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
            <DialogContent>
              <Stack justifyContent="flex-start">
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={() => {
                      navigate({ pathname: page.action });
                      handleMobileMenuClose();
                    }}
                  >
                    <Typography color="inherit">
                      <FormattedMessage id={page.name} />
                    </Typography>
                  </MenuItem>
                ))}
              </Stack>
            </DialogContent>
          </Dialog>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => navigate({ pathname: page.action })}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                <FormattedMessage id={page.name} />
              </Button>
            ))}
          </Box>
          <Box ml="auto">
            <LocaleSwitcher />
          </Box>
          <UserProfileMenu user={user} isLoading={isLoading} />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopBar;
