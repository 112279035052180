import { useQuery, UseQueryResult } from "react-query";
import { IUserProfile } from "../models/IUser";
import { tokenStorageName } from "../utils/utils";
import { IPredictionHistoryItem } from "../models/IPredictHistoryItem";
import { IPayment } from "../models/IPayment";

const API_URL = process.env.REACT_APP_BACKEND_URL;

export const useUser = (
  userId: string
): UseQueryResult<IUserProfile, unknown> => {
  const token = sessionStorage.getItem(tokenStorageName);

  const fetchUser = async (): Promise<IUserProfile> => {
    const response = await fetch(`${API_URL}/api/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Error.getUserFailed");
    }
    const data: IUserProfile = await response.json();
    return data;
  };

  return useQuery(["getUser", userId], fetchUser, {
    enabled: Boolean(userId),
    staleTime: 1000,
  });
};

export const usePredictionHistory = (
  userId: string
): UseQueryResult<IPredictionHistoryItem[], unknown> => {
  const token = sessionStorage.getItem(tokenStorageName);

  const fetchPredictionHistory = async (): Promise<
    IPredictionHistoryItem[]
  > => {
    try {
      const response = await fetch(`${API_URL}/api/tarot-history/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData?.error || "Error.getHistoryFailed";
        throw new Error(errorMessage);
      }

      const data: IPredictionHistoryItem[] = await response.json();
      return data;
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === "TypeError") {
          throw new Error("Error.networkError");
        }
        throw error;
      }
      throw new Error("An error occurred");
    }
  };

  return useQuery(["predictionHistory", userId], fetchPredictionHistory, {
    enabled: Boolean(userId),
    staleTime: 1000,
  });
};

export const useExecutePayment = (paymentId: string, PayerID: string) => {
  const token = sessionStorage.getItem(tokenStorageName);
  return useQuery(
    ["executePayment", paymentId, PayerID],
    () =>
      fetch(
        `${API_URL}/api/execute-paypal-payment?paymentId=${paymentId}&PayerID=${PayerID}`,
        {
          headers: { Authorization: `Bearer ${token}`, credentials: "include" },
        }
      ).then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      }),
    { enabled: Boolean(paymentId && PayerID) }
  );
};

export const useEthTokenToUsdtRate = (cryptoTokenName: string) => {
  const getTokenToUsdtRate = async () => {
    const response = await fetch(
      `https://min-api.cryptocompare.com/data/price?fsym=${cryptoTokenName}&tsyms=USD`
    );
    const data = await response.json();
    return data.USD;
  };
  return useQuery(`${cryptoTokenName}ToUsdtRate`, getTokenToUsdtRate, {
    staleTime: 1000 * 60 * 5,
  });
};

export const useUserPayments = (
  userId: string
): UseQueryResult<IPayment[], unknown> => {
  const token = sessionStorage.getItem(tokenStorageName);

  const fetchUserPayments = async (): Promise<IPayment[]> => {
    const response = await fetch(`${API_URL}/api/payments/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Error.getUserPaymentsFailed");
    }
    const data: IPayment[] = await response.json();
    return data;
  };

  return useQuery(["getUserPayments", userId], fetchUserPayments, {
    enabled: Boolean(userId),
    staleTime: 1000,
  });
};
