import { Grid } from "@mui/material";
import { ITarotCardResponse } from "../models/IPrediction";
import { TarotCard } from "./TarotCard";
import { getCardImagePath } from "../utils/utils";
import { LoadingComponent } from "./LoadingComponent";

interface IPredictionCardsViewProps {
  cards: ITarotCardResponse[];
}

export const PredictionStandardCardsView = (
  props: IPredictionCardsViewProps
) => {
  const { cards } = props;

  if (!cards || cards.length === 0) {
    return <LoadingComponent isLoading />;
  }

  return (
    <Grid
      item
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {cards.map((x) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          key={x.number}
          container
          justifyContent="center"
        >
          <TarotCard
            number={x.number}
            name={x.clearName}
            reversed={x.reversed}
            image={getCardImagePath(x.clearName)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
