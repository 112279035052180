import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { IEthPaymentData } from "../models/IEthPaymentData";
import { IPrediction } from "../models/IPrediction";
import { IPredictionRequest } from "../models/IPredictionRequest";
import { ILoginData, IUser, IUserProfile } from "../models/IUser";
import { IUserRequest } from "../models/IUserRequest";
import { tokenStorageName, userIdStorageName } from "../utils/utils";

const API_URL = process.env.REACT_APP_BACKEND_URL;

export const useAddUser = () => {
  const sendToBackend = async (userData: IUser) => {
    const response = await fetch(`${API_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
      credentials: "include",
    });
    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData?.error || "Error.registrationFailed";
      throw new Error(errorMessage);
    }
    return response.json();
  };

  return useMutation(sendToBackend);
};

export const useLogin = () => {
  const login = async (loginData: ILoginData) => {
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData?.error || "Error.loginFailed";
        throw new Error(errorMessage);
      }

      const data = await response.json();
      sessionStorage.setItem(tokenStorageName, data.token);
      sessionStorage.setItem(userIdStorageName, data.id);

      return data;
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === "TypeError") {
          throw new Error("Error.networkError");
        }
        throw error;
      }
      throw new Error("An error occurred");
    }
  };

  return useMutation(login);
};

export const usePrediction = () => {
  const prediction = useCallback(
    async (question: IPredictionRequest): Promise<IPrediction> => {
      const token = sessionStorage.getItem(tokenStorageName);
      const userId = sessionStorage.getItem(userIdStorageName);
      if (!userId || !token) {
        throw new Error("Errors.notAuthorized");
      }
      question.userId = userId;

      const response = await fetch(`${API_URL}/api/predict`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(question),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData?.error || "Error.predictionFailed");
      }

      return await response.json();
    },
    []
  );

  return useMutation(prediction);
};

export const useGoogleLogin = () => {
  const googleLogin = async (googleToken: string) => {
    const response = await fetch(`${API_URL}/google-login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: googleToken }),
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage =
        errorData?.error || "GoogleButton.failedToLoginWithGoogle";
      throw new Error(errorMessage);
    }

    const data = await response.json();

    return data;
  };

  return useMutation(googleLogin);
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  const updateUser = async ({
    userId,
    updatedData,
  }: {
    userId: string;
    updatedData: IUserProfile;
  }) => {
    const token = sessionStorage.getItem(tokenStorageName);
    if (!token) {
      throw new Error("Errors.notAuthorized");
    }

    const response = await fetch(`${API_URL}/api/users/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedData),
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData?.error || "Error.updateUserFailed";
      throw new Error(errorMessage);
    }

    const data = await response.json();

    await queryClient.invalidateQueries("getUser");

    return data;
  };

  return useMutation(updateUser);
};

export const useUpdateUserPassword = () => {
  const updateUserPassword = async ({
    userId,
    newPassword,
  }: {
    userId: string;
    newPassword: string;
  }) => {
    const token = sessionStorage.getItem(tokenStorageName);
    if (!token) {
      throw new Error("Errors.notAuthorized");
    }

    const response = await fetch(`${API_URL}/api/users/${userId}/password`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ newPassword }),
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData?.error || "Error.updatePasswordFailed";
      throw new Error(errorMessage);
    }

    return response.json();
  };

  return useMutation(updateUserPassword);
};

export const useCreatePayment = () => {
  const token = sessionStorage.getItem(tokenStorageName);
  const userId = sessionStorage.getItem(userIdStorageName);

  return useMutation(
    async ({ amount, quantity }: { amount: number; quantity: number }) => {
      const response = await fetch(`${API_URL}/api/create-paypal-payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ amount, userId, quantity }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const data = await response.json();
      return data;
    },
    {
      onError: (error: Error) => {
        alert(`Error: ${error.message}`);
      },
      onSuccess: (data) => {
        window.location.href = data.approval_url;
      },
    }
  );
};

export const usePromoCode = () => {
  const usePromo = async ({
    promo,
    userId,
  }: {
    promo: string;
    userId: string;
  }) => {
    const token = sessionStorage.getItem(tokenStorageName);
    if (!token) {
      throw new Error("Errors.notAuthorized");
    }

    const response = await fetch(`${API_URL}/api/use-promocode`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ promo, userId }),
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData?.error;
      throw new Error(errorMessage);
    }

    return response.json();
  };

  return useMutation(usePromo);
};

export const useEthPaymentExecute = () => {
  const queryClient = useQueryClient();

  const executePayment = useCallback(async (data: IEthPaymentData) => {
    const token = sessionStorage.getItem(tokenStorageName);
    if (!token) {
      throw new Error("Errors.notAuthorized");
    }

    const response = await fetch(`${API_URL}/api/eth-payment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.error || "Error.paymentFailed");
    }

    return await response.json();
  }, []);

  return useMutation(executePayment, {
    onSuccess: () => {
      queryClient.invalidateQueries("getUserPayments");
    },
  });
};

export const useEthPaymentCreate = () => {
  const createPayment = useCallback(async (data: IEthPaymentData) => {
    const token = sessionStorage.getItem(tokenStorageName);
    if (!token) {
      throw new Error("Errors.notAuthorized");
    }

    const response = await fetch(`${API_URL}/api/create-eth-payment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
      credentials: "include",
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.error || "Error.paymentFailed");
    }

    return await response.json();
  }, []);

  return useMutation(createPayment);
};

export const useSendUserRequest = () => {
  const sendUserRequest = async ({
    userEmail,
    userRequest,
    userId,
  }: IUserRequest) => {
    try {
      const response = await fetch(`${API_URL}/send-user-request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, userEmail, userRequest }),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData?.error;
        throw new Error(errorMessage);
      }

      return response.json();
    } catch (error) {
      throw error;
    }
  };
  return useMutation(sendUserRequest);
};
