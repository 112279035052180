import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useLogin } from "../hooks/useMutation";
import { ILoginData } from "../models/IUser";
import { ErrorBox } from "./ErrorBox";
import GoogleSignInButton from "./GoogleSignInButton";
import { EMAIL_PATTERN } from "../utils/utils";
import { FormattedMessage, useIntl } from "react-intl";
import { LocaleSwitcher } from "./LocaleSwitcher";

const LoginForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginData>();

  const { formatMessage } = useIntl();

  const { mutate: doLogin, isError, isLoading, error } = useLogin();

  const onSubmit = (data: ILoginData) => {
    const updatedData = {
      ...data,
      email: data.email.toLowerCase().trim(),
      password: data.password.trim(),
    };
    doLogin(updatedData, {
      onSuccess: () => {
        window.location.href = "/";
      },
      onError: (error) => {
        console.error("Failed to login:", error);
      },
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack gap={2}>
      {isError && error ? <ErrorBox error={error.toString()} /> : null}
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <LocaleSwitcher />
        <Grid
          container
          spacing={1}
          direction="column"
          display="flex"
          justifyContent="flex-start"
        >
          <Grid item>
            <TextField
              label={
                <FormattedMessage
                  id="emailLabel"
                  defaultMessage="AbraKadabra"
                />
              }
              variant="outlined"
              {...register("email", {
                required: formatMessage({ id: "emailIsRequired" }),
                pattern: {
                  value: EMAIL_PATTERN,
                  message: formatMessage({ id: "invalidEmailAddress" }),
                },
              })}
              error={!!errors.email}
              helperText={errors.email?.message}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item>
            <TextField
              label={
                <FormattedMessage
                  id="passwordLabel"
                  defaultMessage="AbraKadabra"
                />
              }
              type="password"
              variant="outlined"
              {...register("password", {
                required: formatMessage({ id: "passwordIsRequired" }),
              })}
              error={!!errors.password}
              helperText={errors.password?.message}
              fullWidth
              margin="normal"
            />
          </Grid>

          <Grid item>
            <Stack gap={2} justifyContent="center" mb={1} mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                <FormattedMessage id="LoginForm.loginButton" />
              </Button>
              <GoogleSignInButton />
            </Stack>
          </Grid>
        </Grid>
      </form>
      <Box>
        <Link typography="body1" href="/register" alignSelf="center">
          <FormattedMessage id="LoginForm.registerLink" />
        </Link>
      </Box>
    </Stack>
  );
};

export default LoginForm;
