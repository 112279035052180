import { alpha, createTheme } from "@mui/material/styles";
import {
  BLACK,
  BRIGHT_GREEN,
  CHARCOAL,
  DARKER_GREY,
  DARK_BLACK,
  DARK_GREY,
  GREY_ALPHA_DARK,
  LIGHT_BLUE_GREY,
  LIGHT_GREY,
  LIGHT_WHITE,
  LIME_GREEN,
  MIDNIGHT_BLUE,
  NOBLE_GOLD,
  RED,
  SLATE_GREY,
  WHITE,
  WHITE_ALPHA_LIGHT,
  WHITE_ALPHA_MEDIUM,
  YELLOW,
} from "./colors";
import { MAIN_FONT } from "./fonts";

const theme = createTheme({
  palette: {
    primary: {
      main: DARK_GREY,
      contrastText: WHITE,
    },
    secondary: {
      main: LIGHT_GREY,
      contrastText: BLACK,
    },
    warning: {
      main: NOBLE_GOLD,
      contrastText: YELLOW,
    },
    success: {
      main: BRIGHT_GREEN,
      contrastText: LIME_GREEN,
    },
    error: {
      main: RED,
    },
    background: {
      default: CHARCOAL,
      paper: MIDNIGHT_BLUE,
    },
    text: {
      primary: WHITE,
      secondary: LIGHT_BLUE_GREY,
    },
  },
  typography: {
    fontFamily: MAIN_FONT,
    h1: {
      fontSize: "2.5rem",
      color: WHITE,
    },
    h2: {
      fontSize: "2rem",
      color: WHITE,
    },
    subtitle2: {
      fontSize: "0.8rem",
    },
    body1: {
      fontSize: "1rem",
      color: WHITE,
    },
    button: {
      textTransform: "none",
    },
    subtitle1: {
      fontSize: "0.4rem",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: BLACK,
          color: WHITE,
          border: `1px solid ${NOBLE_GOLD}`,
        },
        arrow: {
          color: BLACK,
          "&::before": {
            boxShadow: `0 0 0 1px ${NOBLE_GOLD}`,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: MAIN_FONT,
          fontSize: "1rem",
          fontWeight: 520,
          color: NOBLE_GOLD,
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
            textDecorationStyle: "dotted",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          disableRipple: true,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {},
        containedPrimary: {
          backgroundColor: DARK_GREY,
          "&:hover": {
            backgroundColor: DARKER_GREY,
            color: NOBLE_GOLD,
          },
          "&.Mui-disabled": {
            backgroundColor: DARK_GREY,
            color: SLATE_GREY,
          },
        },
        textPrimary: {
          "&:hover": {
            color: NOBLE_GOLD,
          },
          "&.Mui-disabled": {
            color: SLATE_GREY,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: MAIN_FONT,
          fontSize: "1rem",
          fontWeight: 500,
          "&:hover": {
            color: NOBLE_GOLD,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: WHITE_ALPHA_LIGHT,
            backgroundColor: alpha(BLACK, 0.2),
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: WHITE_ALPHA_MEDIUM,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: NOBLE_GOLD,
            borderWidth: "1px",
          },
        },
        input: {
          color: WHITE,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: DARK_BLACK,
          color: NOBLE_GOLD,
          boxShadow: `0 0 8px ${GREY_ALPHA_DARK}`,
          border: `1px solid ${NOBLE_GOLD}`,
          borderRadius: "8px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: LIGHT_WHITE,
          "&.Mui-focused": {
            color: NOBLE_GOLD,
          },
        },
      },
    },
  },
});

export default theme;
